import logo from "../../assets/logo.png";
import { AiOutlineMail, AiOutlinePhone } from "react-icons/ai";
import { Link } from "react-scroll";
export default function Footer() {
  return (
    <div className="max-w-full mx-auto" id="contatos">
      <footer className="pt-6 pb-4 bg-white rounded-lg shadow px-6 dark:bg-gray-800">
        <div className="flex flex-col lg:flex-row lg:items-center lg:justify-between gap-2">
          <a href="#/" className="flex items-center mb-4 md:mb-0">
            <img src={logo} className="mr-4 h-10" alt="PFG Brasil Logo" />
          </a>
          <div className="flex text-md items-center mb-4 md:mb-0 text-gray-500">
            <AiOutlineMail className="mr-2" />
            <span>
              <a
                href="mailto:brasilpfg@gmail.com"
                target="_blank"
                rel="noreferrer"
              >
                brasilpfg@gmail.com
              </a>
            </span>
          </div>
          <div className="flex items-center mb-4 md:mb-0 text-gray-500 text-md">
            <AiOutlinePhone className="mr-2" />
            <span>
              <a
                href="https://api.whatsapp.com/send?phone=5598985224283&text=Patr%C3%ADcia%20Maranh%C3%A3o%20(Diretora%20Administrativa)"
                target="_blank"
                rel="noreferrer"
              >
                +55 (98) 98522-4283
              </a>
            </span>
          </div>
          <div className="flex items-center mb-4 md:mb-0 text-gray-500 text-md">
            <AiOutlinePhone className="mr-2" />
            <span>
              <a
                href="https://api.whatsapp.com/send?phone=5598991281022&text=Gilson%20Roberto%20(Diretor%20de%20Projetos)"
                target="_blank"
                rel="noreferrer"
              >
                +55 (98) 99128-1022
              </a>
            </span>
          </div>
          <ul className="flex flex-wrap items-center mb-6 sm:mb-0">
            <li>
              <Link
                to="header"
                smooth={true}
                duration={500}
                className="mr-4 text-md text-gray-500 md:mr-6 dark:text-gray-400 hover:cursor-pointer"
              >
                Sobre nós
              </Link>
            </li>
            <li>
              <Link
                to="missao"
                smooth={true}
                duration={500}
                className="mr-4 text-md text-gray-500 md:mr-6 dark:text-gray-400 hover:cursor-pointer"
              >
                Nossa missão
              </Link>
            </li>
            <li>
              <Link
                to="resultados"
                smooth={true}
                duration={500}
                className="mr-4 text-md text-gray-500 md:mr-6 dark:text-gray-400 hover:cursor-pointer"
              >
                Resultados
              </Link>
            </li>
          </ul>
        </div>
        <hr className="my-4 border-gray-200 dark:border-gray-700 " />
        <div className="flex flex-col sm:flex-row justify-between align-center">
          <div>
            <span className="block text-sm text-gray-500 dark:text-gray-400">
              © 2024{" "}
              <a href={logo} className="hover:underline">
                PFG Brasil™
              </a>
              . Todos os direitos reservados.
            </span>
          </div>
          <div>
            <span className="block text-sm text-gray-500 dark:text-gray-400 mt-3 sm:mt-0">
              Desenvolvido por:{" "}
              <a
                href="https://github.com/riordansantos"
                target="_blank"
                rel="noreferrer noopener"
                className="hover:underline"
              >
                https://github.com/riordansantos
              </a>
            </span>
          </div>
        </div>
      </footer>
    </div>
  );
}

