import { motion } from "framer-motion";
import { useInView } from "react-intersection-observer";
import gallery1 from "../../assets/gallery1.jpeg";
import gallery2 from "../../assets/gallery2.jpeg";
import gallery3 from "../../assets/gallery3.jpeg";
import gallery4 from "../../assets/gallery4.jpeg";
import { FaMouse } from "react-icons/fa";
export default function Gallery() {
  const [ref1, inView1] = useInView({
    triggerOnce: false, // A animação acontecerá apenas uma vez
    threshold: 0.1,
  });
  const [ref2, inView2] = useInView({
    triggerOnce: false, // A animação acontecerá apenas uma vez
    threshold: 0.1,
  });

  const [refTitle, inViewTitle] = useInView({
    triggerOnce: false, // A animação acontecerá apenas uma vez
  });
  return (
    <section className="bg-[#035797] text-white body-font" id="resultados">
      <div className="container px-5 py-14 mx-auto">
        <div className="flex flex-col text-center w-full mb-14">
          <motion.h2
            className="text-3xl md:text-4xl lg:text-5xl font-semibold text-white"
            ref={refTitle}
            initial={{ opacity: 0 }}
            animate={{ opacity: inViewTitle ? 1 : 0 }}
            transition={{ duration: 1 }}
          >
            Resultados
          </motion.h2>
          <p className="lg:w-2/3 mx-auto leading-relaxed text-base pt-4 hidden md:block">
            <b>Passe o mouse</b> sobre as imagens para ver o antes e o depois de
            alguns dos nossos resultados.
          </p>
          <p className="lg:w-2/3 mx-auto leading-relaxed text-base pt-4 md:hidden">
            <b>Toque nas imagens</b> para ver o antes e o depois de alguns dos nossos
            resultados.
          </p>
          <div className="mx-auto pt-4">
            <motion.div
              animate={{ x: ["0%", "20%", "0%"] }}
              transition={{ repeat: Infinity, duration: 1 }}
            >
              <FaMouse size={32} />
            </motion.div>
          </div>
        </div>
        <div className="grid grid-cols-1 sm:grid-cols-2 gap-4">
          {/* Repita este bloco para cada par de imagens na galeria */}
          <motion.div
            className="relative min-h-[350px] h-64"
            ref={ref1}
            animate={{ scale: inView1 ? 1 : 0 }}
            transition={{ duration: 1 }}
          >
            <p className="absolute top-[-40px] left-0 w-full text-center text-2xl font-bold">
              Talude de até 80º
            </p>
            <img
              alt="antes"
              className="absolute inset-0 w-full h-full object-cover object-center"
              src={gallery1}
            />
            <motion.img
              alt="depois"
              className="absolute inset-0 w-full h-full object-cover object-center cursor-pointer"
              src={gallery2}
              initial={{ opacity: 0 }}
              whileHover={{ opacity: 1 }}
              transition={{ duration: 1 }}
              whileTap={{ opacity: 1 }}
            />
          </motion.div>
          <motion.div
            className="relative min-h-[350px] h-64 mt-10 sm:mt-0"
            ref={ref2}
            animate={{ scale: inView2 ? 1 : 0 }}
            transition={{ duration: 1 }}
          >
            <p className="absolute top-[-40px] left-0 w-full text-center text-2xl font-bold">
              Área plana
            </p>
            <img
              alt="antes"
              className="absolute inset-0 w-full h-full object-cover object-center"
              src={gallery3}
            />
            <motion.img
              alt="depois"
              className="absolute inset-0 w-full h-full object-cover object-center cursor-pointer"
              src={gallery4}
              initial={{ opacity: 0 }}
              whileHover={{ opacity: 1 }}
              transition={{ duration: 1 }}
              whileTap={{ opacity: 1 }}
            />
          </motion.div>
        </div>
      </div>
    </section>
  );
}
