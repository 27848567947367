import banner5 from "../../assets/banner5.png";
import { motion } from "framer-motion";
import { useInView } from "react-intersection-observer";
import { FaCheckCircle } from "react-icons/fa";
export default function Content() {
  const [ref, inView] = useInView({
    triggerOnce: false, // Change this to false if you want the animation to trigger again whenever it comes in view
  });

  const variants = {
    hidden: { opacity: 0, x: 100 }, // Element starts 100px to the right
    show: {
      opacity: 1,
      x: 0, // Element slides in to its original position
      transition: {
        duration: 0.5,
      },
    },
  };
  return (
    <div
      className="w-full bg-[#035797] min-h-[500px] md:min-h-0 text-white p-4 md:p-8 lg:p-0 flex items-stretch"
      id="missao"
    >
      <div className="container mx-auto px-4 md:px-8 lg:px-0 text-gray-600">
        <div className="space-y-6 md:space-y-0 md:flex md:items-center lg:gap-12">
          <div className="w-full md:w-4/10 lg:w-40/100 mb-6 md:mb-0 md:flex md:items-center md:pr-8 lg:pr-0">
            <img
              src={banner5}
              alt="banner 4"
              loading="lazy"
              className="w-full h-auto md:h-full"
            />
          </div>
          <motion.div
            ref={ref}
            className="w-full md:w-6/10 lg:w-60/100"
            variants={variants}
            initial="hidden"
            animate={inView ? "show" : "hidden"}
          >
            <h2 className="text-2xl text-white font-bold md:text-4xl">
              Nossa missão
            </h2>
            <p className="mt-6 text-white">
              NA PFG Serviços e Soluções tem como missão a sustentabilidade em
              seu conceito, tornando as cidades mais seguras, agradáveis e
              verdes. Utilizando técnicas da construção e manutenção civil
              inovadoras e a <b>hidrossemeadura</b> de sementes de Gramas,
              Capins, Flores e Pastos.
            </p>
            <div className="text-white">
              <ul className="mt-6 space-y-2">
                <li className="flex items-center">
                  <FaCheckCircle className="mr-2" size={24} />
                  <span>Comprometimento com a sustentabilidade</span>
                </li>
                <li className="flex items-center">
                  <FaCheckCircle className="mr-2" size={24} />
                  <span>Excelência na execução</span>
                </li>
                <li className="flex items-center">
                  <FaCheckCircle className="mr-2" size={24} />
                  <span>
                    Contribuição para a revitalização de áreas degradadas
                  </span>
                </li>
              </ul>
            </div>
          </motion.div>
        </div>
      </div>
    </div>
  );
}
