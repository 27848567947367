import logo from "../../assets/logo.png";
import banner from "../../assets/banner.jpeg";
import banner1 from "../../assets/banner1.jpeg";
import banner2 from "../../assets/banner2.jpeg";
import { Carousel } from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Link } from "react-scroll";
import { useState } from "react";
export default function Header() {
  const [isOpen, setIsOpen] = useState(false);
  const banners = [banner1, banner2, banner];
  const handleMenuClick = () => {
    setIsOpen(!isOpen);
  };
  return (
    <div className="w-full">
      <nav className="bg-white shadow-lg">
        <div className="lg:flex items-center justify-between py-2 px-8 md:px-12">
          <div className="flex justify-between items-center">
            <div className="text-2xl font-bold text-[#035797] md:text-3xl">
              <a href="#/">
                <img src={logo} alt="pfg brasil" className="max-w-[200px] " />
              </a>
            </div>
            <div className="lg:hidden">
              <button
                type="button"
                className="block text-[#035797] hover:text-gray-700 focus:text-gray-700 focus:outline-none"
                onClick={handleMenuClick}
              >
                <svg className="h-6 w-6 fill-current" viewBox="0 0 24 24">
                  <path
                    className="hidden"
                    d="M16.24 14.83a1 1 0 0 1-1.41 1.41L12 13.41l-2.83 2.83a1 1 0 0 1-1.41-1.41L10.59 12 7.76 9.17a1 1 0 0 1 1.41-1.41L12 10.59l2.83-2.83a1 1 0 0 1 1.41 1.41L13.41 12l2.83 2.83z"
                  />
                  <path d="M4 5h16a1 1 0 0 1 0 2H4a1 1 0 1 1 0-2zm0 6h16a1 1 0 0 1 0 2H4a1 1 0 0 1 0-2zm0 6h16a1 1 0 0 1 0 2H4a1 1 0 0 1 0-2z" />
                </svg>
              </button>
            </div>
          </div>
          <div
            className={`flex flex-col lg:flex-row lg:block -mx-2 ${
              isOpen ? "" : "hidden"
            }`}
          >
            <Link
              to="header"
              smooth={true}
              duration={500}
              className="text-[#035797] rounded hover:bg-[#035797] hover:text-gray-100 hover:font-medium py-2 px-2 md:mx-2 font-medium lg:text-lg"
            >
              Sobre nós
            </Link>
            <Link
              to="missao"
              smooth={true}
              duration={500}
              className="text-[#035797] rounded hover:bg-[#035797] hover:text-gray-100 hover:font-medium py-2 px-2 md:mx-2 font-medium lg:text-lg"
            >
              Nossa missão
            </Link>
            <Link
              to="servicos"
              smooth={true}
              duration={500}
              className="text-[#035797] rounded hover:bg-[#035797] hover:text-gray-100 hover:font-medium py-2 px-2 md:mx-2 font-medium lg:text-lg"
            >
              Serviços
            </Link>
            <Link
              to="resultados"
              smooth={true}
              duration={500}
              className="text-[#035797] rounded hover:bg-[#035797] hover:text-gray-100 hover:font-medium py-2 px-2 md:mx-2 font-medium lg:text-lg"
            >
              Resultados
            </Link>
            <Link
              to="contatos"
              smooth={true}
              duration={500}
              className="text-[#035797] rounded hover:bg-[#035797] hover:text-gray-100 hover:font-medium py-2 px-2 md:mx-2 font-medium lg:text-lg"
            >
              Contatos
            </Link>
          </div>
        </div>
      </nav>
      <div className="flex bg-white" style={{ height: "auto" }} id="header">
        <div className="flex items-center text-center lg:text-left px-8 md:px-12 lg:w-1/2 my-4 lg:my-0">
          <div>
            <h2 className="text-2xl font-semibold text-[#035797] md:text-3xl">
              <span>Construindo e melhorando o bem-estar</span>
            </h2>
            <p className="mt-2 text-sm text-gray-600 md:text-lg">
              A PFG Serviços e Soluções esta na área da construção civil desde
              2007, atuando nos estados de Minas Gerais, Bahia, Ceará e agora no
              Maranhão. Atuando na área da construção e manutenção civil e da
              hidrosseadura. Sua satisfação é o nosso maior orgulho!
            </p>
          </div>
        </div>
        <div
          className="hidden lg:block lg:w-1/2"
          style={{ clipPath: "polygon(10% 0, 100% 0%, 100% 100%, 0 100%)" }}
        >
          <Carousel autoPlay infiniteLoop interval={2500}>
            {banners.map((banner, index) => (
              <div key={index} className="h-full object-cover">
                <img
                  src={banner}
                  alt={`banner${index}`}
                  style={{ width: "100%", height: "100%" }}
                />
                <div className="h-full bg-black opacity-25"></div>
              </div>
            ))}
          </Carousel>
        </div>
      </div>
    </div>
  );
}
