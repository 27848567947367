import React from "react";
import Header from "../components/header";
import Content from "../components/content";
import Products from "../components/products";
import Gallery from "../components/gallery";
import Footer from "../components/footer";
function App() {
  return (
    <div className="overflow-x-hidden">
      <Header />
      <Content />
      <Products />
      <Gallery />
      <Footer />
    </div>
  );
}

export default App;
