import { FaSeedling } from "react-icons/fa";
import { GiPollenDust } from "react-icons/gi";
import { LuShrub } from "react-icons/lu";

export default function Products() {
  return (
    <>
      <div className="text-center pt-10 pb-4 bg-gray-100" id="servicos">
        <h2 className="text-2xl sm:text-3xl md:text-4xl lg:text-5xl font-semibold text-gray-700">
          Nossos serviços em <br />
          <span className="text-blue-600">Hidrossemeadura</span>
        </h2>
      </div>
      <div className="py-16 px-6 bg-gray-100">
        <div className="container m-auto px-6 text-gray-500 md:px-12 xl:px-0">
          <div className="mx-auto grid gap-6 md:w-3/4 lg:w-full lg:grid-cols-3">
            <div className="relative bg-white rounded-2xl shadow-xl px-8 py-12 sm:px-12 lg:px-8 mb-5 lg:mb-0">
              <div className="absolute -top-8 left-1/2 transform -translate-x-1/2">
                {/* <FaSeedling className="text-[#035797] w-16 h-16 rounded-full bg-white p-3" /> */}
                <LuShrub className="text-[#035797] w-16 h-16 rounded-full bg-white p-3" />
              </div>
              <div className="mb-2 space-y-4 pt-8">
                <h3 className="text-2xl font-semibold text-[#035797]">Grama</h3>
                <p className="mb-6">
                  Este processo consiste em semear uma mistura homogênea de
                  sementes da grama escolhida e aditivos organominerais em áreas
                  planasou em taludes de até 45º. Muito utilizado por
                  urbanizadoras, construtoras e autopistas. E é cerca de 30%
                  mais barato em comparação com a grama em leiva.
                </p>
              </div>
            </div>
            <div className="relative bg-white rounded-2xl shadow-xl px-8 py-12 sm:px-12 lg:px-8 mb-5 lg:mb-0">
              <div className="absolute -top-8 left-1/2 transform -translate-x-1/2">
                {/* <FaMix className="text-[#035797] w-16 h-16 rounded-full bg-white p-3" /> */}
                <FaSeedling className="text-[#035797] w-16 h-16 rounded-full bg-white p-3" />
              </div>
              <div className="mb-12 space-y-4 pt-8">
                <h3 className="text-2xl font-semibold text-[#035797]">
                  Mix de Sementes
                </h3>
                <p className="mb-6">
                  Já este consiste em semear um Mix de sementes que irão
                  reestruturar o solo, podendo ser aplicado desde em áreas plana
                  até taludes de 80º. Muito utilizado por mineradoras,
                  autopistas e fazendas, uma vez que pode ser aplicada em pastos
                  para alimentação do gado.
                </p>
              </div>
            </div>
            <div className="relative bg-white rounded-2xl shadow-xl px-8 py-12 sm:px-12 lg:px-8">
              <div className="absolute -top-8 left-1/2 transform -translate-x-1/2">
                <GiPollenDust className="text-[#035797] w-16 h-16 rounded-full bg-white p-3" />
              </div>
              <div className="mb-12 space-y-4 pt-8">
                <h3 className="text-2xl font-semibold text-[#035797]">
                  Banco de Pólen
                </h3>
                <p className="mb-6">
                  Banco de Pólen é uma técnica inovadora utilizada por
                  autopistas, fazendas de apicultura, fazendas solares, ao lado
                  de lavouras e em canteiro rodoviários. Consiste em semear
                  sementes de flores polínicas junto a aditivos minerais para o
                  fortalecimento da fauna e da flora da localidade aplicada.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
